/* Light and Dark Theme Colors */
:root {
  /* Light Theme */
  --background-color: #FFFFFF;
  --text-color: #1A1A1A;
  --link-color: #388E3C;
  --navbar-bg-color: #F5F5F5;
  --border-color: #4A4A4A;
  --overlay-bg: rgba(0, 0, 0, 0.3);
  --hamburger-line-color: #1A1A1A;
  --close-button-color: rgb(0, 0, 0);
}

@media (prefers-color-scheme: dark) {
  :root {
    /* Dark Theme */
    --background-color: #000000;
    --text-color: #D9D2B0;
    --link-color: #D9D2B0;
    --navbar-bg-color: #2A2A2A;
    --border-color: #888888;
    --overlay-bg: rgba(255, 255, 255, 0.15);
    --hamburger-line-color: #D9D2B0;
    --close-button-color: #FFFFFF;
  }
}

/* General App and Body Styles */
body, .App {
  margin: 5px;
  padding: 5px;
  font-family: 'Asap', sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
  text-align: center;
}

/* Logo Animation */
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Navbar Styles */
.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 30px;
  background-color: var(--navbar-bg-color);
  border: 2px solid var(--border-color);
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Navbar Logo */
.navbar-logo {
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
  color: var(--text-color);
}

.navbar-icon {
  width: 30px;
  height: 30px;
  margin-left: 8px;
}

/* Navbar Links for Desktop */
.navbar-links {
  display: flex;
  gap: 20px;
}

.navbar-links a {
  text-decoration: none;
  color: var(--text-color);
  font-weight: 500;
  padding: 5px 10px;
  transition: background-color 0.3s ease;
  border-radius: 8px;
}

.navbar-links a:hover {
  background-color: rgba(0, 0, 0, 0.1);
  color: var(--link-color);
}

/* Hamburger Icon (for mobile) */
.hamburger-icon {
  display: none;
  flex-direction: column;
  cursor: pointer;
  gap: 5px;
}

.hamburger-icon .line {
  width: 25px;
  height: 3px;
  background-color: var(--hamburger-line-color);
  border-radius: 2px;
}

/* Close Button */
.close-button {
  background: none;
  border: none;
  font-size: 24px;
  color: var(--close-button-color);
  padding: 20px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  left: 10px;
}

/* Show close button only on mobile */
@media (min-width: 769px) {
  .close-button {
    display: none;
  }
}

.close-button:hover {
  color: var(--link-color);
}

/* Overlay for Blurred Background */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--overlay-bg);
  backdrop-filter: blur(5px);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  z-index: 999;
}

.overlay.active {
  opacity: 1;
  visibility: visible;
}

/* Responsive Design */
@media (max-width: 768px) {
  .navbar {
    padding: 10px;
  }

  .navbar-logo {
    font-size: 20px;
  }

  .navbar-links {
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: 50vw;
    background-color: var(--background-color);
    color: var(--text-color);
    border-left: 2px solid var(--border-color);
    box-shadow: -2px 0 8px rgba(0, 0, 0, 0.1);
    transform: translateX(100%);
    transition: transform 0.3s ease;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 50px;
    gap: 20px;
    display: block;
    opacity: 0;
    z-index: 1000;
  }

  .navbar-links.open {
    transform: translateX(0);
    opacity: 1;
  }

  .navbar-links a {
    display: block;
    padding: 15px 20px;
    text-align: left;
    width: 100%;
  }

  .hamburger-icon {
    display: flex;
  }

  /* Close Button */
  .close-button {
    background: none;
    border: none;
    font-size: 24px;
    color: var(--close-button-color);
    padding: 20px;
    cursor: pointer;
    position: absolute;
    top: 5px;
    left: 5px;
  }

  .close-button:hover {
    color: var(--link-color);
  }
}

@media (max-width: 480px) {
  .navbar-icon {
    width: 25px;
    height: 25px;
  }

  .navbar-links {
    gap: 15px;
  }
}

h1 {
  text-align: center;
  margin: 20px;
  color: #ffdd57;
}

.verifier-container {
  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
  background: #1e1e1e;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.verifier-container input[type="file"] {
  margin: 10px 0;
  padding: 8px;
  border: 1px solid #444;
  border-radius: 4px;
  background-color: #2e2e2e;
  color: #fff;
}

.verifier-container button {
  padding: 10px 20px;
  margin: 10px 5px;
  background-color: #ffdd57;
  color: #121212;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.verifier-container button:disabled {
  background-color: #555;
  cursor: not-allowed;
}

.verifier-container progress {
  width: 100%;
  height: 20px;
  margin: 10px 0;
}

.results-table {
  width: 100%;
  margin-top: 20px;
  border-collapse: collapse;
}

.results-table th, .results-table td {
  padding: 10px;
  border: 1px solid #444;
  text-align: left;
}

.results-table th {
  background-color: #2e2e2e;
  color: #ffdd57;
}

.results-table td {
  background-color: #1e1e1e;
}